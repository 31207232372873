<script setup lang="ts">
  import type { SearchResultInterface } from '~/EastclinicVueApi';
  import SeoState from '~/state/Search/SeoState';

  const headerStore = useHeaderStore();
  const { showModal } = storeToRefs(useSearchPanelStore());

  const search = ref(null);
  const input = ref(null);

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const seoState = new SeoState();
  const inputValue = ref(seoState.searchSeoString);
  const showMobileModal = ref(false);

  const { focused } = useFocus(input);

  const debouncedInput = refDebounced(inputValue, 200);

  let currentScroll = 0;

  const showResults = ref(false);

  const searchSeoResults = computed(() => seoState.searchSeoResults.value);

  const isLabelUp = computed(() => {
    return focused.value || inputValue.value.length > 0;
  });

  const textHighLight = (text: string, substring: string) => {
    if (substring === '') {
      return text;
    }
    const regex = new RegExp(substring, 'ig');
    return text.replaceAll(regex, (match) => `<span style="font-weight:600;">${match}</span>`);
  };

  const closeMobileModal = () => {
    headerStore.closeMobileSearchModal();
    seoState.resetSearch();
    showModal.value = false;
    showResults.value = false;
    showMobileModal.value = false;
    // возвращаю обратно при закрытии поиска
    if (isMobile.value) {
      document.documentElement.style.overflowY = 'initial';
      document.documentElement.style.touchAction = 'initial';
      document.body.style.touchAction = 'initial';
      const html = document.getElementsByTagName('html')[0];
      html.scrollTop = currentScroll;
    }
  };

  const focusInput = () => {
    focused.value = true;
    showResults.value = true;
    showMobileModal.value = true;
    if (isMobile.value) {
      document.documentElement.style.overflowY = 'hidden';
      document.documentElement.style.touchAction = 'none';
      document.body.style.touchAction = 'none';
      setTimeout(() => {
        const html = document.getElementsByTagName('html')[0];
        currentScroll = html.scrollTop;
        html.scrollTop = 0;
      }, 50);
    }
  };

  const goToSearchResult = (searchResult: SearchResultInterface|string) => {
    // inputValue.value = searchResult.title;
    closeMobileModal();
    seoState.goSeoUrl(searchResult);
  };

  watch(debouncedInput, (newInputValue) => {
    seoState.setSearchSeoString(newInputValue);
  });

  onClickOutside(search, () => {
    showResults.value = false;
    if (showMobileModal.value) {
      closeMobileModal();
    }
  });

  const safariScroll = () => {
    setTimeout(() => {
      if (focused.value) {
        const html = document.getElementsByTagName('html')[0];
        html.scrollTop = 0;
      }
    }, 50);
  };

  onMounted(() => {
    window.visualViewport?.addEventListener('resize', safariScroll);
  });
  onUnmounted(() => {
    window.visualViewport?.removeEventListener('resize', safariScroll);
  });
</script>

<template>
  <div
    ref="search"
    class="main-search"
    :class="{'mobile-modal': showMobileModal}"
    @click="focusInput"
  >
    <IconArrowLeft class="arrow" @click.stop="closeMobileModal" />
    <IconMagnifer class="magnifer" />
    <div class="input-wrapper">
      <input
        ref="input"
        v-model="inputValue"
        class="main-search-input"
        type="text"
        placeholder=""
      >
      <span :class="`floating-label${isLabelUp ?'-up':''}`">Поиск врача, услуги, заболевания</span>
    </div>
    <IconClose
      v-show="inputValue.length>0"
      class="close"
      @click.stop="goToSearchResult('/vrachi')"
    />
    <TransitionFade>
      <div v-if="showResults" class="search-results-container">
        <div v-if="searchSeoResults.length === 0">
          <div class="no-results">
            <div class="no-results-wrapper">
              <span class="bold-text">Такого нет</span>
              <span class="suggestion-text">Но вот, что у нас есть</span>
            </div>
          </div>
          <div class="popular">
            Популярное
          </div>
          <SearchPanelSearchResult
            v-for="item in seoState.initSearchResults.value"
            :key="item.id"
            :type="item.type"
            :text="textHighLight(item.title, seoState.searchSeoString.value)"
            @click.stop="goToSearchResult(item)"
          />
        </div>
        <SearchPanelSearchResult
          v-for="item in searchSeoResults"
          :key="item.id"
          :type="item.type"
          :text="textHighLight(item.title, seoState.searchSeoString.value)"
          @click.stop="goToSearchResult(item)"
        />
      </div>
    </TransitionFade>
    <div
      v-show="showMobileModal"
      class="overlay"
      @click.stop="closeMobileModal"
    ></div>
  </div>
</template>

<style scoped lang="scss">
.main-search {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 1.125rem 1rem;
  border-radius: 20px 0px 0px 20px;
  background-color: white;
  cursor: text;
  @media (max-width: 1023px) {
    border-radius: 20px;
    width: 100%;
    border: 1px solid #E4E7EF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }
}
.mobile-modal {
  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    border-radius: 0;
    box-shadow: none;
    & .arrow {
      display: inline;
    }
    & .magnifer {
      display: none;
    }
    & .search-results-container {
      height: calc(100vh - 80px);
    }
  }
}
.overlay {
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: none;
  background-color: white;
  @media (min-width: 1024px) {
    display: none;
  }
}
.arrow {
  font-size: 1.5rem;
  margin: 0;
  cursor: pointer;
  display: none;
}
.magnifer {
  font-size: 1.5rem;
  margin: 0;
  color: transparent;
}
.close {
  position: absolute;
  right: 1rem;
  font-size: 1.5rem;
  margin: 0;
  color: #878FA2;
  cursor: pointer;
}
.input-wrapper {
  position: relative;
  top: 10px;
  width: calc(100% - 55px);
}
@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.main-search-input {
  display: flex;
  width: 100%;
  border: none;
  color: #262633;
  font-weight: 500;
  line-height: 125%;
  padding: 0;
  &:focus {
    outline: none;
    animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
  }
  /* &:focus ~ .floating-label, &:not(:placeholder-shown) ~ .floating-label {
    top: -18px;
    font-size: 12px;
    line-height: 133.333%;
    text-overflow: inherit;
    width: 95%;
  } */
}
.floating-label {
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: -9px;
  transition: 0.2s ease all;
  font-weight: 500;
  color: #878FA2;
  line-height: 125%;
  width: 100%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}
.floating-label-up {
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: -18px;
  font-size: 12px;
  line-height: 133.333%;
  text-overflow: inherit;
  width: 95%;

  white-space:nowrap;
  overflow:hidden;
  color: #878FA2;
  transition: 0.2s ease all;
  font-weight: 500;
}
.search-results-container {
  @include scroll;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1rem);
  height: 25rem;
  left: -1rem;
  top: 4.5rem;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0 0 0 2.25rem;
}
.no-results {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.popular {
  margin: 12px 20px 8px 20px;
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.bold-text {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 1rem;
  font-weight: 600;
  line-height: 125%;
}
.suggestion-text {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
</style>
