<script setup lang="ts">
  import type { ClinicInterface } from '~/EastclinicVueApi';

  const { currentRoute } = useRouter();

  const headerStore = useHeaderStore();
  const { isClickedOnClinic } = storeToRefs(useSearchPanelStore());

  const select = ref(null);
  const chevron = ref(null);

  const clinicsStore = useClinicsStore();
  const { clinics, currentClinic } = storeToRefs(clinicsStore);

  const showClinics = ref(false);

  const animateChevron = (position: 'up'|'down') => {
    if (position === 'up') {
      useAnimate(chevron, [{ transform: 'rotate(0deg)' }, { transform: 'rotate(180deg)' }], 400);
      return;
    }
    useAnimate(chevron, [{ transform: 'rotate(180deg)' }, { transform: 'rotate(0deg)' }], 400);
  };

  const toggleClinic = () => {
    showClinics.value = !showClinics.value;
    if (showClinics.value) {
      animateChevron('up');
    } else {
      animateChevron('down');
      isClickedOnClinic.value = true;
    }
  };

  const selectClinic = (clinic: ClinicInterface) => {
    headerStore.closeMobileSearchModal();
    useClinicsStore().selectClinic(clinic);
    isClickedOnClinic.value = true;
  };

  const closeSelect = () => {
    headerStore.closeMobileSearchModal();
    if (!showClinics.value) {
      return;
    }
    showClinics.value = false;
    animateChevron('down');
  };

  const removeClinic = () => {
    clinicsStore.setCurrentClinic(null);

    if (currentRoute.value.name === 'Neurologist') {
      navigateTo({ name: 'Neurologist' });
      return;
    }
    const newPath = currentRoute.value.path.split('/').slice(0, -1).join('/');
    navigateTo(newPath);
  };

  const clickOnAllClinics = () => {
    headerStore.closeMobileSearchModal();
    navigateTo('/vrachi');
  };

  onClickOutside(select, () => {
    if (showClinics.value) {
      closeSelect();
    }
  });
  watch(currentRoute, () => {
    closeSelect();
  });
</script>

<template>
  <div
    ref="select"
    class="clinic-search"
    :class="{'mobile-modal': showClinics}"
    @click="toggleClinic"
  >
    <div class="select-wrapper">
      <div class="left">
        <IconArrowLeft class="arrow" @click.stop="closeSelect" />
        <IconHomeSmile class="home-smile" />
        <div class="mobile-select-text">
          Выберите клинику
        </div>
        <div v-if="currentClinic" class="selected-clinic">
          <div class="select-text">
            Выберите клинику
          </div>
          <div class="clinic-metro">
            <div class="circle" :class="`m-${currentClinic.node_color}`"></div>
            <span class="text-main">
              {{ currentClinic.node_address }}
            </span>
            <div class="clinic-text">
              <IconDirectionsWalk class="icon" />
              <span class="text-secondary">
                {{ clinicGetTextSecondary(currentClinic.way, currentClinic.way_time) }}
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="$route.path !== '/'" class="selected-clinic">
          <div class="select-text">
            Выберите клинику
          </div>
          <div class="clinic-metro">
            <span class="text-main">
              Все клиники
            </span>
          </div>
        </div>
        <div v-else class="select-text">
          Выберите клинику
        </div>
      </div>
      <div class="right">
        <IconClose
          v-show="currentClinic"
          class="close"
          @click.stop="removeClinic"
        />
        <IconChevronDown
          ref="chevron"
          class="chevron-down"
          :style="{ transform: `rotate(${showClinics?'180deg':'0'})` }"
        />
      </div>
    </div>
    <TransitionFade>
      <div v-if="showClinics" class="clinic-results-container">
        <SearchPanelAllClinics @click="clickOnAllClinics" />
        <!-- id !== 42 это данные от компонента "Consultation" -->
        <SearchPanelClinicResult
          v-for="clinic in clinics?.filter(c => c.id !== 42)"
          :key="clinic.id"
          :circle-color="String(clinic.node_color)"
          :text-main="clinic.node_address"
          :text-secondary="clinicGetTextSecondary(clinic?.way, clinic.way_time)"
          :text-address="clinic?.address ?? ''"
          @click="selectClinic(clinic)"
        />
        <!-- <SearchPanelConsultation /> -->
      </div>
    </TransitionFade>
    <div
      v-show="showClinics"
      class="overlay"
      @click.stop="closeSelect"
    ></div>
  </div>
</template>

<style scoped lang="scss">
.clinic-search {
  position: relative;
  display: flex;
  width: 50%;
  height: 60px;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  padding: 1.125rem 1rem;
  border-radius: 0px 20px 20px 0px;
  cursor: pointer;
  @media (max-width: 1023px) {
    border-radius: 20px;
    width: 100%;
    border: 1px solid #E4E7EF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }
}
.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 1023px) {
    &::before {
      content: '';
      width: 0;
      height: 40px;
      position: absolute;
      border: 1px solid #E5E5E7;
      top: 10;
      /* столько же, сколько padding по бокам у clinic-search */
      left: -1rem;
    }
  }
}
.left {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: calc(100% - 64px);
  overflow: hidden;
}
.right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.select-text {
  color: #878FA2;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  user-select: none;
}
.selected-clinic {
  display: flex;
  flex-direction: column;
  user-select: none;
  & .select-text {
    font-size: 0.75rem;
    line-height: 130%;
  }
}
.home-smile {
  min-width: 1.5rem;
  font-size: 1.5rem;
  margin: 0;
  color: transparent;
}
.chevron-down {
  font-size: 1.5rem;
  margin: 0;
  color: #878FA2;
}
.clinic-results-container {
  @include scroll;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1rem);
  height: 23.5rem;
  right: -1rem;
  top: 4.5rem;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 0 0 1.6rem 0;
}
.mobile-modal {
  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    z-index: 1;
    border-radius: 0;
    box-shadow: none;
    & .arrow, & .mobile-select-text {
      display: inline;
    }
    & .mobile-select-text {
      @media (max-width: 335px) {
        font-size: 15px;
      }
    }
    & .home-smile, & .close, & .chevron-down, & .selected-clinic, & .select-text {
      display: none;
    }
    & .clinic-results-container {
      height: calc(100vh - 80px);
    }
    & .left {
      gap: 73px;
    }
  }
}
.arrow {
  font-size: 1.5rem;
  margin: 0;
  cursor: pointer;
  display: none;
}
.overlay {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  @media (width >= 1023px) {
    display: none;
  }
}
.clinic-metro {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.circle {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  padding: 0px 1px;
}
.text-main {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.icon {
  min-width: 14px;
  font-size: 14px;
  color: #878FA2;
}
.clinic-text {
  display: flex;
}
.text-secondary {
  color: #878FA2;
  font-family: "Roboto Flex";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 128.571%;
  white-space: nowrap;
}
.mobile-select-text {
  display: none;
  font-weight: 500;
}
.close {
  font-size: 1.5rem;
  margin: 0;
  color: #878FA2;
  cursor: pointer;
}
/* styles for metro color from backend */
.m-1 {
  background-color: #ee2d24;
}
.m-2 {
  background-color: #58ba36;
}
.m-3 {
  background-color: #0078bf;
}
.m-6 {
  background-color: #F48335;
}
.m-d1 {
  background-color: #f6a600;
}
.m-myt {
  background-color: #3399cc;
}
.m-lyb {
  background-color: #8F479D;
}
</style>
